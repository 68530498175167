.about-us-container {
    width: 90%;
    max-width: 1500px;
    padding: 20px;
    text-align: center;
    margin: 0 auto;
}

.about-us-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 4em;
}

.about-us-content {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
}

.text-content {
    flex: 1;
    padding-right: 20px;
    
}

.text-content ol>li {
    font-weight: bold;
}

.image-content {
    flex: 1;
    max-width: 600px;
    text-align: right;
}

.image-content img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.aboutus-discription-section {
    width: 80%;
    margin-bottom: 20px;
    font-size: 18px;
    color: #343a40;
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 8px;
    text-align: justify;
}

@media (max-width: 780px) {
    .about-us-content {
        flex-direction: row;
        text-align: left;
    }
     
    .text-content {
        padding-right: 20px;
        text-align: left;
    } 

    .image-content {
        margin-top: 0;
        text-align: right;
    }
 
    .image-content img {
        max-width: 80%;
    } 
}