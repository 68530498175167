body {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  color: #343a40;
  margin: 0;
  padding: 0;
}

.header {
  height: 40px;
}

.container h1 {
  /* color: #007BFF; */
  color: black;
}

.container .form-control,
.container .btn-primary {
  border-radius: 0;
}
 
.container .btn-primary {
  /* background-color: #007BFF; */
  background-color: rgb(70, 69, 69);
  color: white;
  border: none;
} 

.main-content {
  padding-bottom: 100px;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 10px;
  }
}

