/* Header.css */
header {
    width: 100%;
    margin: 0 auto;
}

.top-bar {
    background-color: #f8f9fa;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.top-bar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact-info {
    display: flex;
    gap: 20px;
    font-size: 14px;
}

.contact-info a {
    color: #333;
    text-decoration: none;
}

.social-media {
    display: flex;
    gap: 20px;
}

.social-media a {
    color: #333;
    text-decoration: none;
    font-size: 14px;
}

.main-navbar {
    background-color: white;
    padding: 10px 0;
}

.navbar-brand-img {
    height: 60px; /* Adjust this value to make the logo smaller */
    width: auto;
}

.nav-link {
    color: #000000 !important;
    font-size: 16px;
}

.phone-icon {
    display: inline-block;
    margin-right: 5px;
}

.email-icon {
    display: inline;
    margin-right: 8px;
}

@media (max-width: 768px) {
    .contact-info {
        text-align: center;
        flex-direction: column;
    }

    .social-media {
        text-align: center;
        flex-direction: column;
    }

    .navbar-collapse {
        justify-content: center;
    }
}
