.container.mt-5 {
    max-width: 600px;
    margin: auto;
}

.form-group label {
    font-weight: bold;
}

.form-group .form-control {
    margin-bottom: 15px;
}

.form-group .btn-primary {
    /* background-color: #007bff; */
    border: none;
}

