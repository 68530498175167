.quick-links {
    color: #fff;
  }
  
  .quick-links h4 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .quick-links ul {
    list-style-type: none;
    padding: 0;
  }
  
  .quick-links ul li {
    margin-bottom: 8px;
  }
  
  .quick-links ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .quick-links ul li a:hover {
    text-decoration: underline;
  }
  