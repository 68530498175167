.gallery-container {
  /*display: flex;*/
  /*justify-content: space-between;*/
  width: 90%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;
}

.gallery-title h1{
  margin-bottom: 20px 0;
  font-size: 4em;
  font-weight: bold;
  text-align: left;
}

.gallery-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: left;
  align-items: flex-start;
}

.gallery-content p {
  font-size: 20px;
  font-weight: bold;
}

.text-content {
  flex: 1;
  padding-right: 20px;
}

.text-content ol>li {
  font-weight: bold;
}

.gallery {
  flex: 2;
  display: grid;
  grid-template-columns: repeat(2, minmax(400px, 1fr));
  gap: 20px;
  padding: 20px;
  margin: 0 auto;
}

.gallery-item {
  width: 100%;
  box-sizing: border-box;
}

.gallery-item img {
  width: 100%;
  height: 600px;
  display: block;
  object-fit: cover;
  /* Ensures images cover the container */
  border-radius: 8px;
  /* Optional: Adds rounded corners to images */
}

@media (max-width: 760px) {
  .gallery-content {
    flex-direction: column;
    align-items: center;
  }

  .gallery-title h1 {
    font-size: 1.5em;
  }

  .text-content {
    padding-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  .gallery {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .gallery-item img {
    height: auto;
  }
  
}