.footer-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  background-color: #000; /* Adjust the background color as needed */
  color: #fff; /* Adjust the text color as needed */
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 20px;
  flex-wrap: wrap; /* added new*/
}

.footer-column {
  flex: 1;
  padding: 0 20px;
  min-width: 200px; /* new */
}

.footer-column h4 {
  margin-bottom: 10px;
  font-size: 18px;
}

.footer-column p,
.footer-column ul,
.footer-column a {
  margin: 0;
  font-size: 14px;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 8px;
}

.footer-column a {
  color: #fff; /* Adjust the link color as needed */
  text-decoration: none;
}

.footer-column a:hover {
  text-decoration: underline;
}

.footer-bottom {
  text-align: center;
  padding-top: 10px;
  border-top: 1px solid #444; /* Adjust the border color as needed */
}

.footer-column.social-media {
  margin-left: 0; /*300px;*/
  display: flex; /*new*/
  justify-content: center; /*new */
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    margin-bottom: 20px;
  }

  .footer-column.social-media {
    margin-top: 20px;
  }
  
}
  
  

