.home {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.welcome-section {
    width: 100%;
    /* max-width: 2000px; */
    margin: 0 auto;
    background-image: url('../../assets/images/179_Russell_Indooroopilly.jpg');
    background-size: cover;
    background-position: center;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 50px;
}

.welcome-section h1 {
    font-size: 5rem;
    margin: 0;
    font-weight: bold;
}

.description-section {
    width: 80%;
    margin-bottom: 20px;
    font-size: 18px;
    color: #343a40;
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 8px;
    text-align: justify;
}

.description-section h1, h2{
    font-weight: bold;
    
}

.description-section p {
    margin: 10px 0;
}

.carousel-section {
    width: 50%;
    padding: 20px;
    background-color: white;
    margin-bottom: 50px;
  }
  
  .carousel-item img {
    height: 800px;
    object-fit: cover;
  }

.indented-list {
    list-style: disc;
    padding-left: 30px;
    margin-left: 50px;
}

  @media (max-width: 768px) {
    .welcome-section {
        height: auto;
        padding: 20px;
        text-align: center;
    }

    .welcome-section h1 {
        font-size: 2rem;
    }

    .description-section {
        width: 90%;
        font-size: 16px;
    }

    .carousel-section {
        width: 100%;
    }

    .carousel-item img {
        height: auto;
    }
  }